import Head from 'next/head'

interface Props {
  title: string
}

const PageTitle: React.FC<Props> = ({ title }: Props) => {
  return (
    <Head>
      <title key="page-title">{`${title} - Treload`}</title>
    </Head>
  )
}

export default PageTitle
