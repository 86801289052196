import { useState, useEffect } from 'react'

import { useFormik } from 'formik'
import Cookie from 'js-cookie'
import type { NextPage } from 'next'
import Link from 'next/link'
import { AlertCircle } from 'react-feather'
import { Alert, CardTitle, CardText, Form, Label, Input, Button, FormFeedback } from 'reactstrap'

import LoginLayout from '@/components/layouts/login_layout'
import PageTitle from '@/components/page_title'
import { login } from '@/utils/api/authentication'

interface FormValues {
  email: string
  password: string
}

const Login: NextPage = () => {
  const initialValues: FormValues = { email: '', password: '' }
  const [baseError, setBaseError] = useState<string | null>(null)
  const [isChrome, setIsChrome] = useState<boolean>(false)

  const validate = (values: FormValues) => {
    const errors = {} as { email?: string; password?: string }

    if (!values.password) {
      errors.password = 'Required'
    }

    if (!values.email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }

    return errors
  }

  const resetBaseError = () => {
    if (baseError) {
      setBaseError(null)
    }
  }

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    validate,
    onSubmit: (values) => {
      resetBaseError()

      login(values.email, values.password)
        .then((resp) => {
          const token = resp.headers.authorization.split(' ')[1]

          Cookie.set('token', token)
          formik.setStatus({ success: true })
          formik.setSubmitting(false)

          window.location.pathname = '/loads'
        })
        .catch((err) => {
          if (err.response?.status === 422) {
            formik.setErrors({ email: err?.response?.data || '' })
          } else {
            setBaseError('Oops, something went wrong.')
          }

          formik.setStatus({ success: false })
          formik.setSubmitting(false)
        })
    },
  })

  useEffect(() => {
    if (!window?.chrome) {
      setIsChrome(false)
    } else {
      setIsChrome(true)
    }
  }, [])

  return (
    <LoginLayout>
      <PageTitle title="Sign in" />
      <CardTitle className="fw-bold mb-1 text-center" tag="h2">
        Welcome to Treload! 👋
      </CardTitle>
      {baseError && (
        <Alert className="p-1 d-flex justify-content-between" color="danger">
          Something went wrong.
          <AlertCircle size={18} />
        </Alert>
      )}
      {!isChrome && (
        <Alert className="p-1 d-flex justify-content-between" color="warning">
          Please be aware that Google Chrome browser is recommended for best performance.
          <AlertCircle size={18} />
        </Alert>
      )}
      <CardText className="mb-2">Please sign in to your account</CardText>
      <Form className="auth-login-form mt-2" onSubmit={formik.handleSubmit}>
        <div className="mb-1">
          <Label className="form-label" for="email">
            Email
          </Label>
          <Input
            autoFocus
            id="email"
            invalid={!!formik.errors.email}
            name="email"
            onChange={formik.handleChange}
            placeholder="john@example.com"
            value={formik.values.email}
          />
          <FormFeedback>{formik.errors.email}</FormFeedback>
        </div>
        <div className="mb-1">
          <div className="d-flex justify-content-between">
            <Label className="form-label" for="password">
              Password
            </Label>
            <small>
              <Link href="/forgot-password" passHref>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a tabIndex={-1}> Forgot password? </a>
              </Link>
            </small>
          </div>
          <Input
            id="password"
            invalid={!!formik.errors.password}
            name="password"
            onChange={formik.handleChange}
            placeholder="password"
            type="password"
            value={formik.values.password}
          />
          {formik.errors.password && <FormFeedback>{formik.errors.password}</FormFeedback>}
        </div>

        <Button block color="primary" disabled={formik.isSubmitting} to="/">
          Sign in
        </Button>
      </Form>
    </LoginLayout>
  )
}

export default Login
